import { projectData } from "../data/projectData";
import images from "url:../assets/images/*.*";
export default function initProjectData() {
  const projectWrapper = document.querySelectorAll(".project-wrapper")[0]
let htmlString = ''
  projectData.forEach(project => {

    //   ROW
    let row = document.createElement("div")
    row.setAttribute("class", "row");

    //   Column
    let col = document.createElement("div")
    col.setAttribute("class", "col-lg-6 col-sm-12");

    row.append(col)

    //   indvidual Project Wrapper
    let indvidualProjectWrapper = document.createElement("div")
    indvidualProjectWrapper.setAttribute("class", "project-wrapper__text load-hidden");


    col.append(indvidualProjectWrapper)

    //  title
    let title = document.createElement("h3")
    title.setAttribute("class", "project-wrapper__text-title");
    title.innerText = project.id
    indvidualProjectWrapper.append(title)


    // DESCRIPTION
    //   description Wrapper
    let descriptionWrapper = document.createElement("div")
    indvidualProjectWrapper.append(descriptionWrapper)
    //  DESCRIPTION
    let description = document.createElement("p")
    description.setAttribute("class", "mb-4");
    description.innerHTML = project.description +'</br></br>' + project.technologies_used
    descriptionWrapper.append(description)

    if(project.deployed_link != ''){
        //  see live link
        let seeLiveLink = document.createElement("a")
        seeLiveLink.setAttribute("class", "cta-btn cta-btn--hero");
        seeLiveLink.setAttribute("rel", "noreferrer");
        seeLiveLink.setAttribute("target", "_blank");
        seeLiveLink.setAttribute("href", project.deployed_link);
        seeLiveLink.innerText = "See Live"
        indvidualProjectWrapper.append(seeLiveLink)
    }


    if(project.github_link != ''){
        //  see source code
        let sourceCodeLink = document.createElement("a")
        sourceCodeLink.setAttribute("class", "cta-btn text-color-main");
        sourceCodeLink.setAttribute("rel", "noreferrer");
        sourceCodeLink.setAttribute("target", "_blank");
        sourceCodeLink.setAttribute("href", project.github_link);
        sourceCodeLink.innerText = "Source Code"
        indvidualProjectWrapper.append(sourceCodeLink)
    }


    let col2 = document.createElement("div")
    col2.setAttribute("class", "col-lg-6 col-sm-12");

    row.append(col2)

    //   indvidual Project Wrapper 2
    let indvidualProjectWrapper2 = document.createElement("div")
    indvidualProjectWrapper2.setAttribute("class", "project-wrapper__image load-hidden");


    col2.append(indvidualProjectWrapper2)

    //  see image link
    let imageLink = document.createElement("a")
    imageLink.setAttribute("rel", "noreferrer");
    if(project.deployed_link != ''){
        imageLink.setAttribute("target", "_blank");
        imageLink.setAttribute("href", project.deployed_link);
    }

    //  see image link
    let imageWrapper = document.createElement("div")
    imageWrapper.setAttribute("data-tilt", "");
    imageWrapper.setAttribute("data-tilt-max", "4");
    imageWrapper.setAttribute("data-tilt-glare", "true");
    imageWrapper.setAttribute("data-tilt-max-glare", "0.5");
    imageWrapper.setAttribute("class", "thumbnail rounded js-tilt");
    imageLink.append(imageWrapper)
    //  see image 
    let imageSrc = document.createElement("img")
    imageSrc.setAttribute("alt", "Project Image");
    let url = project.image.split('.')
    imageSrc.src = images[url[0]][url[1]]

    imageSrc.setAttribute("class", "img-fluid");
    imageWrapper.append(imageSrc)



    indvidualProjectWrapper2.append(imageLink)

    projectWrapper.append(row)
   

  });
}
