const _temp0 = require("url:./baby_shower.jpg");

const _temp1 = require("url:./client_quiz.jpg");

const _temp2 = require("url:./dbz.jpg");

const _temp3 = require("url:./diperi_app.jpg");

const _temp4 = require("url:./flash_card.jpg");

const _temp5 = require("url:./hr_derby.jpg");

const _temp6 = require("url:./master_your_summer.jpg");

const _temp7 = require("url:./mobile_flashcards.jpg");

const _temp8 = require("url:./myreads.jpg");

const _temp9 = require("url:./topfact.jpg");

const _temp10 = require("url:./wouldYouRather.jpg");

module.exports = {
  "baby_shower":   {
    "jpg": _temp0
  },
  "client_quiz":   {
    "jpg": _temp1
  },
  "dbz":   {
    "jpg": _temp2
  },
  "diperi_app":   {
    "jpg": _temp3
  },
  "flash_card":   {
    "jpg": _temp4
  },
  "hr_derby":   {
    "jpg": _temp5
  },
  "master_your_summer":   {
    "jpg": _temp6
  },
  "mobile_flashcards":   {
    "jpg": _temp7
  },
  "myreads":   {
    "jpg": _temp8
  },
  "topfact":   {
    "jpg": _temp9
  },
  "wouldYouRather":   {
    "jpg": _temp10
  }
}