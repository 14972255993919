let imgPath = 'assets/images/'

export const projectData = [
	{
		"id": "DiPeri Family App",
		"github_link": "",
		"deployed_link": "https://diperi.io/",
		"description": "<b>Problem:</b> My spouse and I wanted a way to automate saving our son’s daycare photos including the ones with other students, which they don't let you download instead of downloading each image manually every day. <br/><br/> <b>Solution</b>: Configured a web-hook using the Gmail API to send any email from the daycare email address to my web app server. Parsed the email’s HTML to download the image and upload that image to S3 and store that URL in the Postgres DB, so the frontend web app can access all the photos. I also made a curl request to the image URL to check if there is a download attribute in the HTML, so that I can label the photo as private (I don't want to post pictures to a site with other kids in it or without other parents permission.) Every image is also sent to our family members’ electronic picture frames to get daily updates of our son.",
		"technologies_used": "Technologies used: React, Styled Components, Javascript, Node.js (Express), Gmail API's Push Notification(Pub Sub Architecture), CSS, HTML",
		"image": "diperi_app.jpg"
	},
	{
		"id": "Flash Card App",
		"github_link": "",
		"deployed_link": "https://flashcards.jdiperi.com/",
		"description": "This app is built using Golang on the backend and React on the frontend. The Flashcard app leverages the https://pixabay.com API to automate the flash card creation process. When a user searches for a term, it will pull a bunch of different images to choose from in the Frontend. When a user decides on the image and clicks 'Create Flashcard', a POST request will be sent to the GO server to generate a flash card using the search term, selected image and then it will proceed to download it automatically to your local computer.",
		"technologies_used": "Technologies used: Go, Javascript, React, Semantic UI, CSS, HTML",
		"image": "flash_card.jpg"
	},
	{
		"id": "HR Derby",
		"github_link": "https://github.com/jdiperi88/hr-derby",
		"deployed_link": "https://hr-derby.jdiperi.com/",
		"description": "HR Derby leverages Phaser JS to deliver a classic 2D Home Run Derby experience. Characters animations are implemented through sprites and CSS.",
		"technologies_used": "Technologies used: Javascript, Phaser, CSS, HTML",
		"image": "hr_derby.jpg"
	},
	{
		"id": "Invitation Tracker",
		"github_link": "https://github.com/jdiperi88/invitation_tracker",
		"deployed_link": "https://invitation-tracker.herokuapp.com/",
		"description": "A full-stack web application hosted on Heroku leveraging React on the frontend and Express on the backend.<br/><br/> <b>Problem:</b> My wife and I wanted a way to send custom HTML emails to our baby shower guests and track what invitees are attending and render different responses based on their selection. <br/><br/> <b>Solution</b>: This application leverages Sendgrid by configuring a webhook when a user clicks on our custom HTML email links which sends a response to our web server to record their decision in a Postgres DB.<br/><br/>The link also redirects the user to a frontend where they are displayed a message customized based on their choice.",
		"technologies_used": "Technologies used: React, Sass, Javascript, Node.js (Express), SendGrid WebHook, CSS, HTML",
		"image": "baby_shower.jpg"
	},
	{
		"id": "Mobile Flashcards",
		"github_link": "https://github.com/jdiperi88/mobile-flashcards",
		"deployed_link": "",
		"description": "Built using React Native, Mobile Flashcards allows users to save study decks into their native local storage on their mobile devices. Users are able to add an unlimited amount of cards and decks to their application. Each deck has quiz functionality to allow users to see how well they know their deck, and will receive a grade upon finishing it. </br></br> The decks are stored in local storage and synced up to the redux store. The redux store provides the additional quiz functionality. Users are also allowed to set daily notifications that remind them to study their flashcards everyday",
		"technologies_used": "Technologies used: React Native, Redux, Javascript, AsyncLocalStorage, Expo, StyleSheet API",
		"image": "mobile_flashcards.jpg"
	},
	{
		"id": "Master Your Summer",
		"github_link": "",
		"deployed_link": "",
		"description": "Developed using the microservices architecture technique, Master Your Summer was a campaign site that allowed users to view a variety of recipes provided by the client that were stored using MySQL in an RDS instance, as well as design their own badge that they could share on several social media outlets.</br></br> The badge builder leveraged an image processing npm package on the back-end called jimp. Jimp would take a string, a css hexcode, and multple pngs and compress them into one image that would be uploaded to an S3 bucket so users could download and share them with their friends.",
		"technologies_used": "Technologies used: React,Node-js, Express, MySQL, Sass, AWS(Elastic Beanstalk,S3), Javascript, CSS, HTML.",
		"image": "master_your_summer.jpg"
	},
	{
		"id": "Client Quiz",
		"github_link": "",
		"deployed_link": "",
		"description": "Developed using the microservices architecture technique, Client Quiz front-end was built in react and hosted in a S3 bucket while the API was hosted on Elastic beanstalk and created in Express.</br></br> Client Quiz was an instore web application that allowed users to answer a variety of questions and based on their score, the application would a recommend a product.<br/><br/> The application required a lot of custom validation to check if a user is old enough, and that they answered every question before they could proceed to the next question. Data was stored in the MySQL database.",
		"technologies_used": "Technologies used: React,Node-js, Express, MySQL, Sass, AWS(Elastic Beanstalk,S3), Javascript, CSS, HTML.",
		"image": "client_quiz.jpg"
	},
	{
		"id": "Would You Rather ??",
		"github_link": "https://github.com/jdiperi88/would-you-rather",
		"deployed_link": "https://would-you-rather.netlify.com/",
		"description": "Would You Rather is a react redux application that allows users to create, answer, and vote on questions that were created by the applications users. A user has to be logged in to use the application, and a mock auth system was created to do this. <br/><br/> A user can then create a question that will ask if they would rather do one of two options. After this they may vote on their own questions or questions created by other users which will then move that question into their answer category. Once answered they will receive a point and if they click on the question it will show the percentage of votes each answer received by the global user community. Users can also view where they stand on the leaderboard pointwise as users receive points for each question created and each question answered.",
		"technologies_used": "Technologies used: React, Redux, Sass, Netlify, Redux-thunk, Javascript, CSS, HTML.",
		"image": "wouldYouRather.jpg"
	},
	{
		"id": "My Reads",
		"github_link": "https://github.com/jdiperi88/MyReads",
		"deployed_link": "https://myreads88.netlify.com/",
		"description": "My Reads is a react book tracker that has two main components, a dynamic Status component along with a search component. The search component allowed users to search through the database to find available books and they can choose to filter those into the right tracker category if they have any interest through a select drop down menu",
		"technologies_used": "Technologies used: React Netlify, Javascript, CSS, HTML.",
		"image": "myreads.jpg"
	},
	{
		"id": "TOP FACT",
		"github_link": "https://github.com/jdiperi88/trivia",
		"deployed_link": "https://enigmatic-coast-60319.herokuapp.com/",
		"description": "Built using express in the backend and the EJS template engine in the frontend, Top Fact is an application that connects to the open trivia API. It allows users to register and create their own customized trivia game. Their game is saved in five PSQL tables where they can re-render it at anytime.",
		"technologies_used": "Technologies used: Node-js,Express, Fetch, PSQL, EJS, Authentication, Bcrypt, Javascript, CSS, HTML.",
		"image": "topfact.jpg"
	},
	{
		"id": "Legend of DBZ",
		"github_link": "",
		"deployed_link": "https://dbz.jdiperi.com/",
		"description": "Legend of DBZ is a multiplayer fighting game that utilizes local storage to pass user information between three html pages. There is a character selection page that allows users to chooce from 8 fighters and then they can proceed to  select from 4 stages. The fight page uses collision detection to decrement the life bar and inform the winner.",
		"technologies_used": " Technologies used: Javascript, HTML, and CSS.",
		"image": "dbz.jpg"
	}
]
